import React, { useState } from "react"
import { Button, Card, Container, Form } from "react-bootstrap"
import PrivateWrapper from "./private-wrapper"

import ApiService from "../../services/ApiService"
import { toast } from "react-toastify"
import AdminLayout from "./layout/admin-layout"
import SignUpLinks from "./sign-up-links/sign-up-links"
import UsersDisplay from "./users-display"

export default function AdminPage() {
  const [file, setFile] = useState(null)
  const [tab, setTab] = useState(0)

  const upload = async () => {
    if (file) {
      if (await ApiService.uploadUserCsv(file)) {
        toast("Upload successful.", { type: "success" })
      } else {
        toast("Upload failed.", { type: "error" })
      }
    }
  }

  const fileSelected = async e => {
    var files = e?.target?.files
    if (files && files.length > 0) {
      setFile(files[0])
    }
  }

  const tabClick = (e, key) => {
    e.preventDefault()
    setTab(key)
  }

  return (
    <>
      <PrivateWrapper group="admin">
        <AdminLayout currentTab={tab} tabClick={tabClick}>
          <Container className="my-3">
            {tab === 0 ? <UsersDisplay /> : ""}
            {tab === 1 ? <SignUpLinks /> : ""}
            {tab === 2 ? (
              <Form.Group>
                <h2>Upload Users</h2>
                <Form.Control
                  id="UploadFile"
                  name="UploadFile"
                  type="file"
                  onChange={fileSelected}
                />
                <Button className="mt-4" onClick={upload}>
                  Upload
                </Button>
              </Form.Group>
            ) : (
              ""
            )}
          </Container>
        </AdminLayout>
      </PrivateWrapper>
    </>
  )
}
