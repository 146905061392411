import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import AuthService from "../../services/AuthService"

const PrivateWrapper = ({
  unauthorisedRedirect,
  group,
  children
}) => {

  unauthorisedRedirect = unauthorisedRedirect ?? "/classroom";

  const isBrowser = typeof window !== "undefined"
  const [vis, setVis] = useState(false);

  useEffect(async () => {
    if (await AuthService.isInGroup(group)) {
      setVis(true);
    } else{
      navigate(unauthorisedRedirect)
    }
    
  }, [])

  const componentVis = () => {
    return isBrowser && vis;
  }

  return <>{componentVis() ? children : ""}</>
}

export default PrivateWrapper
