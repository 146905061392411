import React, { useEffect, useState } from "react"
import { Button, Row, Table, Col } from "react-bootstrap"
import ApiService from "../../../services/ApiService"
import DateTimePicker from "react-datetime-picker"

export default function SignUpLinks() {
  const [links, setLinks] = useState(null)

  const [date, dateChange] = useState(null)

  useEffect(async () => {
    load()
  }, [])

  const load = async () => {
    setLinks(await ApiService.listSignUpLinks({ enabled : {eq: true}}))
  }

  const create = async () => {
    await ApiService.createSignUpLink({
      enabled: true,
      expiryDate: date.toISOString(),
    })
    load()
  }
  const disable = async id => {
    await ApiService.disableSignUpLink(id)
    load()
  }

  return (
    <div>
      <h2>Sign Up Links</h2>
      <Row className="mb-3">
        <Col>
        </Col>
        <Col xs="auto">
          <DateTimePicker className="me-2" value={date} onChange={dateChange} />
          <Button onClick={create}>Create</Button>
        </Col>
      </Row>
      <Table bordered>
        <thead>
          <tr>
            <th>Link</th>
            <th>Expiry Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {links?.map(l => (
            <tr key={l.id}>
              <td>{l.id}</td>
              <td>{l.expiryDate}</td>
              <td>
                <Button onClick={() => disable(l.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
