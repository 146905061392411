import React from "react"
import { useEffect } from "react"
import ApiService from "../../services/ApiService"
import * as styles from "./users-display.module.scss"

export default function UsersDisplay() {
  const [users, setUsers] = React.useState([])

  useEffect(() => {
    loadUsers()
  }, [])

  const loadUsers = async () => {
    try {
      const resp = await ApiService.getUsers()
      const users = resp.Users.map(user => {
        const givenName = user.Attributes.find(
          attribute => attribute.Name === "given_name"
        ).Value
        const familyName = user.Attributes.find(
          attribute => attribute.Name === "family_name"
        ).Value
        const email = user.Attributes.find(
          attribute => attribute.Name === "email"
        ).Value
        const phoneNumber = user.Attributes.find(
          attribute => attribute.Name === "phone_number"
        ).Value
        const sub = user.Attributes.find(
          attribute => attribute.Name === "sub"
        ).Value

        return {
          id: sub,
          name: givenName + " " + familyName,
          email,
          phoneNumber,
          status: user.UserStatus,
        }
      })
      setUsers(users)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div>
      <h2>Users Display</h2>

      {users.map(user => (
        <div className={styles.user} key={user.id}>
          <div className={styles.icon}>{user.name[0]}</div>
          <div className="flex-grow-1">
            <div className={styles.name}>{user.name}</div>
            <div className={styles.email}>{user.email}</div>
            <div className={styles.phoneNumber}>{user.phoneNumber}</div>
          </div>
          <div className="px-3">{user.status}</div>
        </div>
      ))}

      {users.length === 0 && <div>No users found.</div>}
    </div>
  )
}
