import * as React from "react"
import PropTypes from "prop-types"
import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"
import awsconfig from "../../../aws-exports"

import "react-toastify/dist/ReactToastify.css"
import "@aws-amplify/ui-react/styles.css"
import "../../../styles/global.scss"
import * as styles from "./admin-layout.module.scss"
import { ToastContainer } from "react-toastify"

import Logo from "../../../images/icon.png"

Amplify.configure(awsconfig)

const AdminLayout = ({ children, currentTab, tabClick }) => {
  return (
    <Authenticator className="classroom-login admin">
      {() => (
        <>
          <div className={styles.admin}>
            <div className={styles.aside}>
              <div className={styles.header}>
                <img className={styles.logo} src={Logo} />
                <h1>Admin</h1>
              </div>
              <div className={styles.menu}>
                <ul>
                  <li className={currentTab == 0 ? styles.active : null}>
                    <a onClick={e => tabClick(e, 0)}>Users</a>
                  </li>
                  <li className={currentTab == 1 ? styles.active : null}>
                    <a onClick={e => tabClick(e, 1)}>Sign-up Links</a>
                  </li>
                  <li className={currentTab == 2 ? styles.active : null}>
                    <a onClick={e => tabClick(e, 2)}>Bulk Upload Users</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.body}>{children}</div>

            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              newestOnTop={false}
            />
          </div>
        </>
      )}
    </Authenticator>
  )
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdminLayout
